import {fullName} from "@/utils/filters/names.filter"
import api from "@/utils/api_factory"
import to from 'await-to-js'
import store from '../../store'

export const defaultDocument = function (documentType) {
  let notes = null
  if (store.getters.currentCompany && store.getters.currentCompany.legal_complement && store.getters.currentCompany.legal_complement !== '') {
    notes = store.getters.currentCompany.legal_complement
  }

  let document = {
    VATRates: [],
    acomptes: [],
    analytical_code: null,
    client: null,
    client_address: null,
    client_address_complement: null,
    client_address_end: null,
    client_city: null,
    client_civility: null,
    client_code: null,
    clientCodeDisplay: store.getters.currentCompany.displayCodeClient,
    client_contact: null,
    client_country: "France",
    client_country_code: "FR",
    client_dom: false,
    client_dom_services: false,
    client_email: null,
    client_export: false,
    client_fax: null,
    client_interlocutor: null,
    client_mobile: null,
    client_name: null,
    client_outsite_eu: false,
    client_phone: null,
    client_professional: false,
    client_rgpd: false,
    client_tva: null,
    client_zip_code: null,
    clientSiren: null,
    clientNic: null,
    comment: null,
    company_address: null,
    company_address_end: null,
    company_cgv: null,
    company_cgv_text: null,
    company_city: null,
    company_commercial_court: null, // Greffe Paris
    company_contact: null,
    company_country: "France",
    company_country_code: "FR",
    company_email: null,
    company_fax: null,
    company_has_aggregation: false,
    company_has_tva_franchise: false,
    company_naf_ape: null,
    company_name: null,
    company_nic: null,
    company_num_rcs: null,
    company_num_trade_directory: null,
    company_phone: null,
    company_reverse_charge: false, // autoliquidation
    company_share_capital: null,
    company_siren: null,
    company_type: null,
    company_zip_code: null,
    currency: null,
    currency_rate: 1,
    custom_data: null,
    date: null,
    delivery_address: null,
    delivery_address_end: null,
    delivery_city: null,
    delivery_contact: null,
    delivery_country: null,
    delivery_country_code: null,
    delivery_email: null,
    delivery_fax: null,
    delivery_name: null,
    delivery_phone: null,
    delivery_zip_code: null,
    discount_total: null,
    discount_total_ht: null, // Remise globale
    discount_total_type: null, // Percent or value
    display_expeditor: false,
    display_rules: {},
    documentModel: null,
    footer_complement: null,
    footer_complement_end: null,
    globalDiscount: null, // Global discount percent (V3 new system)
    hasReduction: false,
    hasSubtotal: false,
    hasUnit: false,
    language: 'fr-FR',
    late_penalty_rate: null,
    mandatory_mentions: null,
    name: null, // Type + number
    notes: notes,
    num_tva: null, // Num TVA intracommunautaires
    number: undefined,
    payment_date: null,
    payment_expiry_date: null,
    payment_methods: [],
    payment_methods_name: null,
    payment_state: null,
    positionCreator: null,
    positionAssigned: null,
    purchase_order: null,
    sale_date: null,
    shipping_method: null,
    shipping_total_ht: null,
    shipping_total_tva: null,
    shipping_tva_rate: null,
    signature: {},
    state: 'draft',
    title: null,
    total_ht: null,
    total_ht_nodiscount: null,
    total_nettopay: null,
    total_ttc: null,
    total_tva: null,
    type: null,
    wuroProud: false,
  }

  if (documentType === 'invoice') {
    return {
      ...document,
      ...{
        acompte: false, // TODO delete (replace by type)
        external_url: null,
        fromquote: null,
        fromquote_total_ht: null,
        invoice_lines: [],
        payments: [],
        credit: false, // Avoir // TODO delete (replace by type)
        creditForInvoice: null,
        sold: false, // TODO delete (replace by type)
        visibility: { type: String, enum: ['public', 'assigned'], default: 'public' },
        dunningRule: undefined,
        dunnings: []
      }
    }
  }

  if (documentType === 'quote') {
    return {
      ...document,
      ...{
        accept_date: null,
        invoiced_date: null,
        payment_delay: null,
        quote_lines: [],
      }
    }
  }

  return document
}

export async function getNewInvoice (currentCompany, currentPosition, currentUser, documentModels = [], countries = [], clientId = null) {
  let mainAddress = {}
  if (currentCompany.addresses && currentCompany.addresses.length > 0) mainAddress = currentCompany.addresses[0]

  let mainPhone = {}
  if (currentCompany.phones && currentCompany.phones.length > 0) mainPhone = currentCompany.phones[0]
  if (!mainPhone._id && currentCompany.mobiles && currentCompany.mobiles.length > 0) mainPhone = currentCompany.mobiles[0]

  let documentModel = null
  if (currentCompany.documentModel) {
    documentModel = documentModels.find(dM => dM._id === currentCompany.documentModel)
  }

  let emptyInvoice = {
    ...defaultDocument('invoice'),
    ...{
      date: new Date(),
      title: '',
      name: "FACTURE",
      draft: true,
      num_tva: currentCompany.tva_number,
      company: currentCompany._id,
      company_name: currentCompany.name,
      company_contact: fullName(currentPosition),
      company_siren: currentCompany.siren,
      company_nic: currentCompany.nic,
      company_share_capital: currentCompany.share_capital,
      company_num_rcs: currentCompany.num_rcs,
      company_num_trade_directory: currentCompany.num_trade_directory,
      company_commercial_court: currentCompany.commercial_court,
      company_naf_ape: currentCompany.naf_ape,
      company_type: currentCompany.company_type_name ? currentCompany.company_type_name : (currentCompany.company_type ? (currentCompany.company_type._id ? currentCompany.company_type.name : currentCompany.company_type) : null),
      company_address: mainAddress.street,
      company_address_end: mainAddress.street_end,
      company_zip_code: mainAddress.zip_code,
      company_city: mainAddress.city,
      company_country: mainAddress.country || 'France',
      company_phone: mainPhone.number,
      company_email: currentCompany.email,
      company_has_tva_franchise: currentCompany.has_tva_franchise,
      company_has_aggregation: currentCompany.has_aggregation,
      company_cgv: currentCompany.cgv_link,
      company_cgv_text: currentCompany.cgv,
      company_include_cgv: currentCompany.company_include_cgv,
      company_include_cgv_physical: currentCompany.company_include_cgv_physical,
      client_country: 'France',
      invoice_lines: [],
      state: 'draft',
      payment_expiry_date: new Date().setDate(new Date().getDate() + parseInt(currentCompany.payment_delay_default, 10)), // Add days to payment limit
      display_rules: {title: null},
      notes: currentCompany.legal_complement ? currentCompany.legal_complement : '',
      late_penalty_rate: 10,
      client_professional: true,
      documentModel: documentModel ? documentModel._id : null,
      footer_complement: documentModel ? documentModel.footer_complement : null,
      footer_complement_end: documentModel ? documentModel.footer_complement_end : null,
      wuroProud: documentModel ? documentModel.wuroProud : null,
      style: documentModel ? {...documentModel} : {},
      currency: "EUR",
      language: "fr-FR",
      user: currentUser._id,
      user_assigned: currentUser._id,
      positionCreator: JSON.parse(JSON.stringify(currentPosition)),
      positionAssigned: JSON.parse(JSON.stringify(currentPosition)),
      visibility: 'public',
      type: 'invoice'
    }
  }

  // EMS SPECIFIC (REMOVE THIS ASAP)
  // TODO A changer et vite c'est immonde de faire un truc comme ça (On pourrait imaginer un champ custom_data sur l'entreprise et si ils ont des customs data alors on ajoute les champs)
  if (currentCompany.name === 'E-monsite') {
    emptyInvoice.custom_data = { fields: [{ id: 'login', label: 'Login', value: '' }, { id: 'reference', label: 'Référence', value: '' }] }
  }

  emptyInvoice = await setClient(emptyInvoice, clientId)

  if (countries) {
    let country = countries.find(country => country.name === emptyInvoice.company_country)
    if (country) {
      emptyInvoice.company_country_code = country.code
    }
  }

  if ((!emptyInvoice.style || (emptyInvoice.style && !emptyInvoice.style.header) || (emptyInvoice.style && emptyInvoice.style.header && !emptyInvoice.style.header.image)) && currentCompany.logo) {
    if (!emptyInvoice.style) emptyInvoice.style = {}
    if (!emptyInvoice.style.header) emptyInvoice.style.header = {}
    emptyInvoice.style.header.image = currentCompany.logo
  }

  if (!emptyInvoice.style || (emptyInvoice.style && !emptyInvoice.style.footer)) {
    if (!emptyInvoice.style) emptyInvoice.style = {}
    if (!emptyInvoice.style.footer) emptyInvoice.style.footer = {}
  }

  return emptyInvoice
}

export async function getNewQuote (currentCompany, currentPosition, currentUser, type, documentModels = [], countries = [], clientId = null) {
  let mainAddress = {}
  if (currentCompany.addresses && currentCompany.addresses.length > 0) mainAddress = currentCompany.addresses[0]

  let mainPhone = {}
  if (currentCompany.phones && currentCompany.phones.length > 0) mainPhone = currentCompany.phones[0]
  if (!mainPhone._id && currentCompany.mobiles && currentCompany.mobiles.length > 0) mainPhone = currentCompany.mobiles[0]

  let documentModel = null
  if (currentCompany.documentModel) {
    documentModel = documentModels.find(dM => dM._id === currentCompany.documentModel)
  }

  let name = 'DEVIS'
  if (type === 'bdc') {
    name = 'Bon de commande'
  }
  if (type === 'proforma') {
    name = 'Facture Pro-forma'
  }

  let emptyQuote = {
    ...defaultDocument('quote'),
    ...{
      date: new Date(),
      title: '',
      name: `${name}`,
      draft: true,
      num_tva: currentCompany.tva_number,
      company: currentCompany._id,
      company_name: currentCompany.name,
      company_contact: fullName(currentPosition),
      company_siren: currentCompany.siren,
      company_nic: currentCompany.nic,
      company_share_capital: currentCompany.share_capital,
      company_num_rcs: currentCompany.num_rcs,
      company_num_trade_directory: currentCompany.num_trade_directory,
      company_commercial_court: currentCompany.commercial_court,
      company_naf_ape: currentCompany.naf_ape,
      company_type: currentCompany.company_type_name ? currentCompany.company_type_name : (currentCompany.company_type ? (currentCompany.company_type._id ? currentCompany.company_type.name : currentCompany.company_type) : null),
      company_address: mainAddress.street,
      company_address_end: mainAddress.street_end,
      company_zip_code: mainAddress.zip_code,
      company_city: mainAddress.city,
      company_country: mainAddress.country || 'France',
      company_phone: mainPhone.number,
      company_email: currentCompany.email,
      company_has_tva_franchise: currentCompany.has_tva_franchise,
      company_has_aggregation: currentCompany.has_aggregation,
      company_cgv: currentCompany.cgv_link,
      company_cgv_text: currentCompany.cgv,
      company_include_cgv: currentCompany.company_include_cgv,
      company_include_cgv_physical: currentCompany.company_include_cgv_physical,
      client_country: 'France',
      notes: currentCompany.legal_complement ? currentCompany.legal_complement : '',
      quote_lines: [],
      state: 'draft',
      expiry_date: new Date().setDate(new Date().getDate() + currentCompany.validity_delay_default), // Add days to limit
      payment_delay: 0,
      display_rules: {},
      late_penalty_rate: 10,
      client_professional: true,
      documentModel: documentModel ? documentModel._id : null,
      footer_complement: documentModel ? documentModel.footer_complement : null,
      footer_complement_end: documentModel ? documentModel.footer_complement_end : null,
      wuroProud: documentModel ? documentModel.wuroProud : null,
      style: documentModel ? {...documentModel} : {},
      currency: "EUR",
      language: "fr-FR",
      user: currentUser._id,
      user_assigned: currentUser._id,
      positionCreator: JSON.parse(JSON.stringify(currentPosition)),
      positionAssigned: JSON.parse(JSON.stringify(currentPosition)),
      type: type,
    }
  }

  emptyQuote = await setClient(emptyQuote, clientId)

  let country = countries.find(country => country.name === emptyQuote.company_country)
  if (country) {
    emptyQuote.company_country_code = country.code
  }

  if ((!emptyQuote.style || (emptyQuote.style && !emptyQuote.style.header) || (emptyQuote.style && emptyQuote.style.header && !emptyQuote.style.header.image)) && currentCompany.logo) {
    if (!emptyQuote.style) emptyQuote.style = {}
    if (!emptyQuote.style.header) emptyQuote.style.header = {}
    emptyQuote.style.header.image = currentCompany.logo
  }

  if (!emptyQuote.style || (emptyQuote.style && !emptyQuote.style.footer)) {
    if (!emptyQuote.style) emptyQuote.style = {}
    if (!emptyQuote.style.footer) emptyQuote.style.footer = {}
  }

  return emptyQuote
}

export async function getNewDeliveryReceipt (currentCompany, currentPosition, currentUser, type, documentModels = [], countries = [], clientId = null) {
  let mainAddress = {}
  if (currentCompany.addresses && currentCompany.addresses.length > 0) mainAddress = currentCompany.addresses[0]

  let mainPhone = {}
  if (currentCompany.phones && currentCompany.phones.length > 0) mainPhone = currentCompany.phones[0]
  if (!mainPhone._id && currentCompany.mobiles && currentCompany.mobiles.length > 0) mainPhone = currentCompany.mobiles[0]

  let documentModel = null
  if (currentCompany.documentModel) {
    documentModel = documentModels.find(dM => dM._id === currentCompany.documentModel)
  }

  let name = 'BON DE LIVRAISON'

  let emptyDocument = {
    date: new Date(),
    title: '',
    name: `${name}`,
    draft: true,
    num_tva: currentCompany.tva_number,
    company: currentCompany._id,
    company_name: currentCompany.name,
    company_contact: fullName(currentPosition),
    company_siren: currentCompany.siren,
    company_nic: currentCompany.nic,
    company_share_capital: currentCompany.share_capital,
    company_num_rcs: currentCompany.num_rcs,
    company_num_trade_directory: currentCompany.num_trade_directory,
    company_commercial_court: currentCompany.commercial_court,
    company_naf_ape: currentCompany.naf_ape,
    company_type: currentCompany.company_type_name ? currentCompany.company_type_name : (currentCompany.company_type ? (currentCompany.company_type._id ? currentCompany.company_type.name : currentCompany.company_type) : null),
    company_address: mainAddress.street,
    company_address_end: mainAddress.street_end,
    company_zip_code: mainAddress.zip_code,
    company_city: mainAddress.city,
    company_country: mainAddress.country || 'France',
    company_phone: mainPhone.number,
    company_email: currentCompany.email,
    company_has_tva_franchise: currentCompany.has_tva_franchise,
    company_has_aggregation: currentCompany.has_aggregation,
    company_cgv: currentCompany.cgv_link,
    company_cgv_text: currentCompany.cgv,
    company_include_cgv: currentCompany.company_include_cgv,
    company_include_cgv_physical: currentCompany.company_include_cgv_physical,
    client_country: 'France',
    notes: currentCompany.legal_complement ? currentCompany.legal_complement : '',
    lines: [],
    state: 'draft',
    display_rules: {},
    client_professional: true,
    documentModel: documentModel ? documentModel._id : null,
    footer_complement: documentModel ? documentModel.footer_complement : null,
    footer_complement_end: documentModel ? documentModel.footer_complement_end : null,
    wuroProud: documentModel ? documentModel.wuroProud : null,
    style: documentModel ? {...documentModel} : {},
    language: "fr-FR",
    user: currentUser._id,
    user_assigned: currentUser._id,
    positionCreator: currentPosition._id,
    positionAssigned: currentPosition._id,
    type: type,
  }

  emptyDocument = await setClient(emptyDocument, clientId)

  let country = countries.find(country => country.name === emptyDocument.company_country)
  if (country) {
    emptyDocument.company_country_code = country.code
  }

  if ((!emptyDocument.style || (emptyDocument.style && !emptyDocument.style.header) || (emptyDocument.style && emptyDocument.style.header && !emptyDocument.style.header.image)) && currentCompany.logo) {
    if (!emptyDocument.style) emptyDocument.style = {}
    if (!emptyDocument.style.header) emptyDocument.style.header = {}
    emptyDocument.style.header.image = currentCompany.logo
  }

  if (!emptyDocument.style || (emptyDocument.style && !emptyDocument.style.footer)) {
    if (!emptyDocument.style) emptyDocument.style = {}
    if (!emptyDocument.style.footer) emptyDocument.style.footer = {}
  }

  return emptyDocument
}

const setClient = async function (document, clientId) {
  if (clientId) {
    let [err, resp] = await to(api.company.client.getOne({ id: clientId }))
    if (err) return document

    const client = resp.data.client

    document.client_name = client.name
    document.client_code = client.client_code
    document.analytical_code = client.analytical_code
    document.client_contact = client.client_contact
    document.client_address = client.address
    document.client_address_end = client.address_end
    document.client_address_complement = client.client_address_complement
    document.client_zip_code = client.zip_code
    document.client_city = client.city
    document.client_country = client.country || 'France'
    document.client_phone = client.phone
    document.client_mobile = client.mobile
    document.client_fax = client.fax
    document.client_email = client.email
    document.client = client._id
    document.clientSiren = client.siren
    document.clientNic = client.nic
    document.client_tva = client.tva_number
  }

  return document
}
