<template>
  <div>
    <b-progress :value="100" variant="dev" id="ProgressDev" height="4px" striped animated class="fixed-top"></b-progress>
    <b-progress :value="100" variant="dev" id="ProgressDev" height="4px" striped animated class="fixed-bottom"></b-progress>

    <b-button id="ButtonDevSideBar" variant="warning" v-b-toggle.sidebar-devbar>
      <b-icon icon="hammer" font-scale="1"></b-icon>
    </b-button>

    <b-sidebar id="sidebar-devbar" title="Dev Bar" bg-variant="warning" :right="right" shadow width="40%">
      <div class="p-1">
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="Notifications" active>
              <div class="mb-3">
                <b-overlay
                  :show="sendNotifications"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <b-button variant="primary" block @click="onSendNotifications">Envoyer les notifications par email</b-button>
                </b-overlay>
              </div>

              <div class="mb-3">
                <b-overlay
                  :show="sendNotificationsCron"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <b-button variant="primary" block @click="onSendNotificationsCron">Démarrer le cron des notifications</b-button>
                </b-overlay>
              </div>
            </b-tab>

            <b-tab title="Test factures">

              <div class="mb-3">
                <b-overlay
                  :show="sendDunningsSubmit"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <b-button variant="primary" block @click="onSendDunnigs">Envoyer les relances</b-button>
                </b-overlay>
              </div>

              <div>
                <b-overlay
                  :show="createRecurrencesSubmit"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <b-button variant="primary" block @click="onCreateRecurrences">Créer les récurrences</b-button>
                </b-overlay>
              </div>
            </b-tab>

            <b-tab title="Luminjo tests">
              <form method="post" @submit.prevent="onTicketTest">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputEmail">Votre email</label>
                    <input type="email" class="form-control" id="inputEmail" v-model="ticketTest.from"/>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="inputSubject">Sujet de votre message</label>
                    <input type="text" class="form-control" id="inputSubject" v-model="ticketTest.subject"/>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="inputSubject">Pièce jointe</label>
                    <input type="file" class="form-control" id="inputFile" @change="onTicketFileChange"/>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="inputContent">Contenu</label>
                    <b-form-textarea
                      class="form-control"
                      id="inputContent"
                      v-model="ticketTest.content"
                      rows="13" max-rows="14"></b-form-textarea>
                  </div>
                  <b-button type="submit" size="lg" variant="primary">Envoyer</b-button>
                </div>
              </form>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import api from '../utils/api_factory'
import to from 'await-to-js'
// Components
import SelectTags from './Form/SelectTags'
import InputImage from "./Form/InputImage"

const invoiceModel = {
  "client_country": "France",
  "client_country_code": "FR",
  "company_country": "France",
  "company_country_code": "FR",
  "currency": "EUR",
  "currency_rate": 1,
  "language": "fr-FR",
  "name": "FACTURE ",
  "payment_methods": [],
  "state": "draft",
  "visibility": "public",
  "invoice_lines": [{
    "price_ht": 515,
    "price_ttc": 618,
    "quantity": 1,
    "total_ht": 515,
    "tva": null,
    "title": "Distributeur d'eau 10L",
    "description": "Test",
    "tva_rate": 20,
  }],
  "comment": null,
  "client_name": "TEST API",
  "client_address": "9 rue Sala",
  "client_address_end": "",
  "client_zip_code": "69002",
  "client_city": "Lyon",
  "client_tva": "",
  "company": null,
  "client_dom_services": false,
  "client_outsite_eu": false,
}

export default {
  name: 'DevBar',
  data () {
    return {
      right: true,
      ticketTest: {},
      ticketTestFile: null,
      photo: null,
      tags: [],
      invoicesCreated: [],
      invoicesWithFailCreated: [],
      sendDunningsSubmit: false,
      sendNotifications: false,
      sendNotificationsCron: false,
      createRecurrencesSubmit: false
    }
  },
  components: { SelectTags, InputImage },
  methods: {
    onCreateFourInvoices: async function () {
      const clients = ['Monsieur Test', "Test 2", "Michel Test", "Madame Test"]

      let promises = []
      this.invoicesCreated = []
      for (let i = 0; i < clients.length; i++) {
        let invoice = JSON.parse(JSON.stringify(invoiceModel))
        invoice.company = this.currentCompany._id

        invoice.client_name = clients[i]
        promises.push(api.testAPI.postInvoice({ body: invoice }))
      }

      let [err, response] = await to(Promise.all(promises))
      if (err) {
        return this.toastError(err.response.data)
      }

      let newInvoiceIds = []
      response.forEach(r => {
        newInvoiceIds.push(r.data.newInvoice._id)
      })
      this.invoicesCreated = newInvoiceIds

      this.toastSuccess('Factures créées.')
    },
    onCreateFourInvoicesWithOneFail: async function () {
      const clients = ['Monsieur Test', "Test 2", "Michel Test", "Madame Test"]

      let promises = []
      this.invoicesWithFailCreated = []
      for (let i = 0; i < clients.length; i++) {
        let invoice = JSON.parse(JSON.stringify(invoiceModel))
        invoice.company = this.currentCompany._id
        invoice.number = 'nextnumber'
        invoice.state = 'waiting'
        if (i === 2) {
          delete invoice.client_address
        }
        invoice.client_name = clients[i]
        promises.push(api.testAPI.postInvoice({body: invoice}))
      }

      let [err, response] = await to(Promise.all(promises))
      if (err) {
        return this.toastError(err.response.data)
      }

      let newInvoiceIds = []
      response.forEach(r => {
        newInvoiceIds.push(r.data.newInvoice._id)
      })
      this.invoicesWithFailCreated = newInvoiceIds

      this.toastSuccess('Factures créées.')
    },
    onUpdateInvoicesWithFail: async function () {
      if (this.invoicesCreated.length === 0) {
        let response = await api.company.invoice.getList({ filters: { state: 'draft', sort: '-createdAt', limit: 4 } })
        if (response && response.data.invoices.length === 4) {
          this.invoicesCreated = response.data.invoices.map(i => i._id)
        }
      }

      if (this.invoicesCreated.length !== 4) {
        return this.toastError({ message: "on peut pas procédé à ce test" })
      }

      let promises = []

      let invoiceIds = [
        this.invoicesCreated[0],
        this.invoicesCreated[1],
        this.invoicesCreated[2],
        this.invoicesCreated[1],
        this.invoicesCreated[3],
      ]

      invoiceIds.forEach(id => {
        let body = { _id: id, state: "waiting" }
        promises.push(api.testAPI.patchInvoice({ body: body, id }))
      })

      let [err, response] = await to(Promise.all(promises))
      if (err) {
        return this.toastError(err.response.data)
      }
      this.toastSuccess('Factures modifiées.')
    },
    onPingUsers () {
      this.$socket.emit('PING_POSITIONS', { positionFrom: this.currentPosition._id, company: this.currentCompany._id })
    },
    onTicketFileChange (e) {
      this.ticketTestFile = e.target.files[0]
    },
    onTicketTest: async function () {
      const formData = new FormData()
      formData.append("file", this.ticketTestFile)
      formData.append('from', this.ticketTest.from)
      formData.append('subject', this.ticketTest.subject)
      formData.append('content', this.ticketTest.content)

      let [err, result] = await to(api.autoSigned.test.createTicket({ body: formData }))
      if (err) {
        this.toastError(err.response.data)
        return
      }

      this.toastSuccess('Ticket envoyé')
    },
    onSendDunnigs: async function () {
      this.sendDunningsSubmit = true

      let [err, result] = await to(api.autoSigned.test.sendDunnings({ body: {} }))
      this.sendDunningsSubmit = false
      if (err) {
        this.toastError(err.response.data)
        return
      }

      this.toastSuccess('Relances envoyées')
    },
    onSendNotifications: async function () {
      this.sendNotifications = true

      let [err, result] = await to(api.autoSigned.test.sendNotifications({ body: {} }))
      this.sendNotifications = false
      if (err) {
        this.toastError(err.response.data)
        return
      }

      this.toastSuccess('Notifications envoyées')
    },
    onSendNotificationsCron: async function () {
      this.sendNotificationsCron = true

      let [err, result] = await to(api.autoSigned.test.sendNotificationsCron({ body: {} }))
      this.sendNotificationsCron = false
      if (err) {
        this.toastError(err.response.data)
        return
      }

      this.toastSuccess('Cron des notifications terminé')
    },
    onCreateRecurrences: async function () {
      this.createRecurrencesSubmit = true

      let [err, result] = await to(api.autoSigned.test.createRecurrences({ body: {} }))
      this.createRecurrencesSubmit = false
      if (err) {
        this.toastError(err.response.data)
        return
      }

      this.toastSuccess('Récurrences créées')
    }
  }
}
</script>

<style scoped lang="css">

#ButtonDevSideBar {
  border-radius: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  position: fixed;
  top: 50%;
  right: 0;
  color: #696969;
}
</style>
