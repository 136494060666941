<template>
  <div class="summary" :class="{'hidden-summary': hiddenSummary}">
    <div class="card center leaderboard">
      <div v-if="isArray">
        <table-statistics v-if="arrays" :arrays="arrays"></table-statistics>
      </div>
      <span class="mb-3" v-else>{{ summaryText }}</span>
      <b-spinner v-show="loading" variant="primary"></b-spinner>
      <div class="d-flex gap-4 align-items-center">
        <button v-if="exportFunc !== null" type="button" class="btn btn-sm btn-light csv" :disabled="loading" v-show="loading===false" @click="loadingCSV" v-b-tooltip="'Extraction de vos lignes sélectionnées dans un fichier en CSV pour éditer sous excel ou un logiciel de comptabilité'"><img class="mr-3 d-none d-lg-inline-block" width="24" src="@/assets/img/csv-icon.svg" alt="export csv"/>{{ $t('global.exports.csv') }}</button>
        <button v-if="pdfFunc !== null" type="button" class="btn btn-sm btn-light pdf" :disabled="loading" v-show="loading===false" @click="loadingPDF" v-b-tooltip="'Téléchargement de vos PDF sélectionnés'"><img class="mr-3 d-none d-lg-inline-block" width="24" src="@/assets/img/pdf-icon.svg" alt="export pdf"/>{{ $t('global.exports.pdf') }}</button>
        <b-button v-if="deleteFunc !== null" variant="danger" @click="onDelete" v-b-tooltip.hover="'Supprimer les éléments sélectionnés'">
          <b-icon icon="trash"></b-icon>
        </b-button>
        <slot name="btn"></slot>
    </div>
    <b-icon icon="x" class="close-icon" @click="hiddenSummary=!hiddenSummary"></b-icon>
  </div>
  <div class="showSummary" @click="hiddenSummary=!hiddenSummary">
    <span class="show-icon">
      <b-icon icon="download" font-scale="2"></b-icon>
    </span>
  </div>
</div>
</template>

<script>
import TableStatistics from "@/components/TableStatistics"

export default {
  name: 'Summary',
  components: {
    TableStatistics
  },
  props: {
    exportFunc: {
      type: Function,
      default: null
    },
    pdfFunc: {
      type: Function,
      default: null
    },
    deleteFunc: {
      type: Function,
      default: null
    },
    summaryText: {
      type: String,
      default: ""
    },
    isArray: {
      type: Boolean,
      default: false
    },
    arrays: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      hiddenSummary: false,
      loading: false
    }
  },
  methods: {
    onDelete () {
      this.deleteBox('Voulez-vous supprimer les éléments sélectionnés ?', async () => {
        await this.deleteFunc()
      })
    },
    loadingCSV () {
      this.loading = true

      this.exportFunc(true)
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    loadingPDF () {
      this.loading = true
      this.pdfFunc(true)
      .finally(() => {
        this.loading = false
      })
      setTimeout(() => {

      }, 1000)
    }
  }
}
</script>

<style scoped lang="css">
@media only screen and (max-width: 768px) {
  .summary {
    bottom: 60px;
    padding: 0;
    margin: 0;
  }
  .summary .show-icon{
    top: -70px;
  }
  .summary > .center {
    width: 100vw;
  }
}
</style>
