import to from "await-to-js"
import api from "../../utils/api_factory"
import {CURRENT_QUOTE} from "../mutation.constant"
import {getNewQuote} from "@/components/Document/document.constant"

const state = {
  currentQuote: {},
  filters: localStorage.getItem('quoteFilters') ? JSON.parse(localStorage.getItem('quoteFilters')) : {}
}

const getters = {
  currentQuote: state => state.currentQuote,
  getQuoteFilters: () => state.filters
}

const actions = {
  $a_getCurrentQuote: async function ({ commit, dispatch, rootState, state }, { id, type, documentModels, countries, client }) {
    if (!id) {
      let quote = await getNewQuote(rootState.company.currentCompany, rootState.authV3.currentPosition, rootState.authV3.currentUser, type, documentModels, countries, client)
      commit(CURRENT_QUOTE, quote)
    } else {
      let [err, resp] = await to(api.company.quote.getOne({ id, filters: { populate: 'positionAssigned positionCreator' } }))
      if (err) return err

      let quote = resp.data.quote
      if (!quote.style) quote.style = {}
      if (!quote.style.footer) quote.style.footer = {}

      commit(CURRENT_QUOTE, resp.data.quote)
    }
  }
}

const mutations = {
  [CURRENT_QUOTE]: (state, quote) => {
    state.currentQuote = quote
  },
  quoteFilters (state, filters) {
    let newFilter = {}
    newFilter.state = filters.state
    newFilter.date = filters.date
    newFilter.minDate = filters.minDate
    newFilter.maxDate = filters.maxDate
    localStorage.setItem('quoteFilters', JSON.stringify(newFilter))
    state.filters = { ...newFilter }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
