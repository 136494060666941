export default (axios, getSignature) => ({
  createTicket (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/test/ticket', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/test/ticket', data.body, { params: data.filters })
  },
  sendDunnings (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/test/send-dunnings', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/test/send-dunnings', data.body, { params: data.filters })
  },
  sendNotifications (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/test/send-notifications', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/test/send-notifications', data.body, { params: data.filters })
  },
  sendNotificationsCron (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/test/start-cron-notifications', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/test/start-cron-notifications', data.body, { params: data.filters })
  },
  createRecurrences (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/test/create-recurrences', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/test/create-recurrences', data.body, { params: data.filters })
  },
})
